import React, { useMemo } from 'react';
import { StaticGoogleMap, Marker } from 'react-static-google-map';
import { Textfit } from 'react-textfit';

import Link from 'components/uiLibrary/Link'; // TODO: Change to use LinkButton
import Image from 'components/uiLibrary/Image';

import classes from './MapView.module.scss';

export const MARKER_SIZES = {
  TINY: 'tiny',
  MID: 'mid',
  SMALL: 'small',
  NORMAL: 'normal',
};

const Map = ({ width, height, zoom, altLabel, latitude, longitude, markerSize, label }) => {
  const { renderHeight, renderWidth } = useMemo(
    () => ({
      renderHeight: Math.round(height),
      renderWidth: Math.round(width),
    }),
    [height, width],
  );

  return (
    <>
      <StaticGoogleMap
        size={`${renderWidth}x${renderHeight}`}
        zoom={zoom}
        apiKey={process.env.GOOGLE_MAPS_KEY}
        as={({ src }) => (
          <Image src={src} width={width} height={height} alt={altLabel} disableNextImage disableCloudinary />
        )}
        scale={4}
        // eslint-disable-next-line react/style-prop-object
        style="feature:poi|element:labels|visibility:off&style=feature:all|element:labels.text|visibility:off"
      >
        <Marker location={{ lat: latitude, lng: longitude }} color="red" size={markerSize} />
      </StaticGoogleMap>
      {label && (
        <Textfit mode="multi" className={classes.label} max={12}>
          {label}
        </Textfit>
      )}
    </>
  );
};

const MapView = ({
  latitude,
  longitude,
  placeId,
  label,
  width,
  height,
  zoom = 15,
  markerSize = MARKER_SIZES.MID,
  withoutLink = false,
}) => {
  const { href, altLabel } = useMemo(() => {
    let query = label;
    if (!query) {
      if (latitude && longitude) {
        query = `${latitude},${longitude}`;
      } else if (placeId) {
        query = placeId;
      }
    }

    const queryParams = new URLSearchParams();
    queryParams.set('api', 1);

    if (placeId) {
      queryParams.set('query_place_id', placeId);
    }
    if (latitude) {
      queryParams.set('lat', latitude);
    }
    if (longitude) {
      queryParams.set('lng', longitude);
    }
    if (query) {
      queryParams.set('query', query);
    }

    return {
      altLabel: `Google Maps | ${label} | ${query}`,
      href: `https://www.google.com/maps/search/?${queryParams.toString()}`,
    };
  }, [label, latitude, longitude, placeId]);

  if (withoutLink) {
    return (
      <div className={classes.wrapper}>
        <Map
          width={width}
          height={height}
          zoom={zoom}
          altLabel={altLabel}
          latitude={latitude}
          longitude={longitude}
          markerSize={markerSize}
          label={label}
        />
      </div>
    );
  }

  return (
    <Link className={classes.wrapper} href={href} title={altLabel} external>
      <Map
        width={width}
        height={height}
        zoom={zoom}
        altLabel={altLabel}
        latitude={latitude}
        longitude={longitude}
        markerSize={markerSize}
        label={label}
      />
    </Link>
  );
};

export default MapView;
