import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { getInitialsFromName } from 'utils';

import classes from './ImageAvatar.module.scss';

const ImageAvatar = ({ name, shape = 'circle', className, height = 60, width = 60, fontSize = 20, type }) => (
  <div
    className={classnames(classes.avatar, {
      [className]: className,
      [classes.avatar_circle]: shape === 'circle',
      [classes.avatar_rect]: shape === 'rect',
      [classes.styleV4]: type === 'V4',
    })}
    style={{ height, width, fontSize }}
  >
    {name ? getInitialsFromName(name) : <PersonOutlineIcon className={classes.profileIcon} />}
  </div>
);

ImageAvatar.propTypes = {
  name: PropTypes.string,
  shape: PropTypes.oneOf(['circle', 'rect']),
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ImageAvatar;
