import React, { useMemo, useState, useRef, useCallback, useEffect, createContext, useContext } from 'react';
import classnames from 'classnames';

// eslint-disable-next-line import/no-cycle
import usePageContext from 'utils/hooks/usePageContext';
import useScrollDirection from 'utils/hooks/useScrollDirection';

import classes from './StickyContainer.module.scss';

const StickyContext = createContext(false);

const useStickyContext = () => useContext(StickyContext);

const StickyContextProvider = ({ children, value }) => (
  <StickyContext.Provider value={value}>{children}</StickyContext.Provider>
);

const StickyContainer = ({
  top = 0,
  children,
  stickyContent,
  isStickyHeader = false,
  enabled = true,
  styles = {},
  withBorder = false,
  allowGlobalHeaderHiding = false,
}) => {
  const ref = useRef(null);
  const [isSticky, setSticky] = useState(false);
  const {
    isHeaderVisible,
    showGlobalHeader,
    hideGlobalHeader,
    hideGlobalNavigation,
    showGlobalNavigation,
  } = usePageContext();

  const threshold = useMemo(() => (isStickyHeader ? 0 : 1), [isStickyHeader]);

  const handleObserver = useCallback(
    entries => {
      const [target] = entries;

      let isStuck = false;
      if (target.boundingClientRect.top < top) {
        isStuck = !target?.isIntersecting;
      }

      if (isStickyHeader || allowGlobalHeaderHiding) {
        if (isStuck) {
          hideGlobalHeader();
          hideGlobalNavigation();
        } else {
          showGlobalHeader();
          showGlobalNavigation();
        }
      }

      setSticky(isStuck);
    },
    [
      hideGlobalHeader,
      showGlobalHeader,
      isStickyHeader,
      top,
      allowGlobalHeaderHiding,
      showGlobalNavigation,
      hideGlobalNavigation,
    ],
  );

  useEffect(() => {
    const element = ref.current;
    let observer;

    if (enabled) {
      const option = { threshold, rootMargin: `-${top}px 0px 0px 0px` };
      observer = new IntersectionObserver(handleObserver, option);
      observer.observe(element);
    }

    return () => {
      if (observer) {
        observer.unobserve(element);
      }
    };
  }, [handleObserver, enabled, threshold, top]);

  useScrollDirection({
    onScrollDown: () => {
      if (isSticky) {
        hideGlobalHeader();
        hideGlobalNavigation();
      }
    },
    onScrollUp: () => {
      showGlobalHeader();
      showGlobalNavigation();
    },
    enabled: (isStickyHeader || allowGlobalHeaderHiding) && enabled,
  });

  const stickyContextValue = useMemo(() => {
    if (isStickyHeader || allowGlobalHeaderHiding) {
      return isSticky && !isHeaderVisible;
    }

    return isSticky;
  }, [isStickyHeader, isSticky, isHeaderVisible, allowGlobalHeaderHiding]);

  if (isStickyHeader) {
    return (
      <>
        <div className={classnames({ [styles?.root]: !!styles?.root })} ref={ref}>
          {children}
        </div>
        {enabled && stickyContextValue && (
          <div
            {...(enabled && { style: { top: `${top - 1}px` } })}
            className={classnames(classes.root, {
              [classes.isHidden]: !stickyContextValue,
              [classes.isHeader]: isStickyHeader,
            })}
          >
            <StickyContextProvider value={stickyContextValue}>{stickyContent || children}</StickyContextProvider>
            {withBorder && (
              <div
                className={classnames(classes.border, {
                  [classes.border__isVisible]: isSticky,
                })}
              />
            )}
          </div>
        )}
      </>
    );
  }

  return (
    <div
      className={classnames(classes.root, { [classes.sticky]: isSticky, [styles?.root]: !!styles?.root })}
      ref={ref}
      {...(enabled && { style: { top: `${top - 1}px` } })}
    >
      <StickyContextProvider value={stickyContextValue}>
        {stickyContextValue && stickyContent ? stickyContent : children}
        {withBorder && !isHeaderVisible && (
          <div
            className={classnames(classes.border, {
              [classes.border__isVisible]: isSticky,
            })}
          />
        )}
      </StickyContextProvider>
    </div>
  );
};

export { useStickyContext, StickyContextProvider };
export default StickyContainer;
