import React from 'react';
import classnames from 'classnames';
import classes from './SeparatorList.module.scss';

export const SEPARATOR_TYPES = {
  BULLET: 'bullet',
  FORWARD_SLASH: 'forward-slash',
  COMMA: 'comma',
};

const SeparatorList = ({ data, styles = {}, separator = SEPARATOR_TYPES.BULLET }) => {
  if (!data?.length) {
    return null;
  }

  return (
    <span className={styles.root}>
      {data?.map((item, index) => (
        <span
          className={classnames({
            [styles?.item]: !!styles?.item,
            [classes.bullet]: separator === SEPARATOR_TYPES.BULLET,
            [classes.forwardSlash]: separator === SEPARATOR_TYPES.FORWARD_SLASH,
            [classes.comma]: separator === SEPARATOR_TYPES.COMMA,
          })}
          key={index}
        >
          {item}
        </span>
      ))}
    </span>
  );
};

SeparatorList.defaultProps = {
  data: [],
  classNames: '',
  separator: SEPARATOR_TYPES.BULLET,
};

export default SeparatorList;
